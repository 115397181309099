<template>
    <modal ref="modalCambiarCondicion" titulo="Seleccionar tipo de comisión" tamano="modal-md" @guardar="guardar">
        <ValidationObserver ref="validacion">
            <cargando v-if="cargando" />
            <div class="row mx-4">
                <div class="col-12">
                    <p class="text-general f-13 mx-3">
                        Condición
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Condición">
                        <el-select v-model="condicion" class="w-100" size="small" placeholder="Selecciona">
                            <el-option
                            v-for="item in condiciones"
                            :key="item.id"
                            :label="item.condicion"
                            :value="item.id"
                            />
                        </el-select>
                        <div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from "~/services/alianzas_leecheros";

export default {
    data(){
        return{
            cargando: false,
            condiciones: [],
            idCedis: this.$route.params.id_cedis,
            condicion: null,
        }
    },
    methods: {
        toggle(){
            this.getCondiciones();
            this.$refs.modalCambiarCondicion.toggle();
        },

        async getCondiciones(){
            try {
                const {data} = await Service.getCondiciones();
                this.condiciones = data.condiciones;
            } catch(e){
                this.error_catch(e);
            }
        },

        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                this.cargando = true;

                const payload = {
                    idCedis: this.idCedis,
                    condicion: this.condicion,
                };

                const {data} = await Service.cambiarCondicionLeecheros(payload);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$emit('update');
                    this.$refs.modalCambiarCondicion.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        },
    },
}
</script>
<style lang="scss" scoped>

</style>
